.monthDayNumbersSelector {
    --hover-blue: hsla(202, 91%, 25%, 0.2);
    --faint-border: 1px solid hsla(202, 91%, 25%, 0.1);
    --primary-blue: hsl(202, 91%, 25%);
    --grey-text: hsl(208, 7%, 46%);


    display: grid;
    grid-template-columns: 200px 1fr;
    padding: 0.25rem 0;

    grid-template-areas:
        "January numbers"
        "Febuary numbers"
        "March numbers"
        "April numbers"
        "May numbers"
        "June numbers"
        "July numbers"
        "August numbers"
        "September numbers"
        "October numbers"
        "Novemeber numbers"
        "December numbers";

}

.monthName {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin: 0 0.75rem 0 0;
    padding: 0.4rem 0.75rem;
    border: var(--faint-border);
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    color: var(--grey-text);
    cursor: pointer;
}

.monthName+.monthName {
    margin-top: 0.4rem;
}

.monthName:hover {
    background-color: var(--hover-blue);
}

.selectedDate,
.unselectedDate {
    font-size: 0.85em;
    opacity: 0.7;
    margin-left: auto;
}

.selectedDate {
    font-size: 1em;
    margin-right: 0.75rem;
    text-transform: lowercase;
    border: none;
}

.numbersGrid {
    grid-area: numbers;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(7, 1fr);
    gap: 1rem;

}

.numbersGrid button {
    display: grid;
    place-items: center;
    cursor: pointer;
    border-radius: 0.25rem;
    border: var(--faint-border);
    padding: 0;
    color: var(--grey-text);
    font-size: 0.875rem;
}

.numbersGrid button:hover {
    background-color: var(--hover-blue);
    color: var(--grey-text);
    ;
}

.numbersGrid [data-active="true"],
[data-active="true"] {
    color: white;
    background-color: var(--primary-blue);
}

/* if active, hover colours same as active state */
.numbersGrid [data-active="true"]:hover,
[data-active="true"]:hover {
    color: white;
    background-color: var(--primary-blue);
    border: var(--faint-border);
}

/* make sure it's in the very last position */
.noneButton {
    grid-column-end: -1;
    grid-row-end: -1
}